// import React from "react";
// import blogmg from "./blogimg/upboxbg1.png";
// import "./blog.css";

// export function Blog() {
//   return (
//     <>
//       <div className="main_blog container">
//         <div className="blogbox">
//           <div className="blogleft">
//             <img src={blogmg} alt="" />
//           </div>
//           <div className="blogright">
//           <div className="blogright-up">hello</div>
//           <div className="blogright-down">world</div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

// import React, { useState } from "react";
// import blogmg from "./blogimg/upboxbg1.png";
// import "./blog.css";

// export function Blog() {
//   // State to control the visibility of the full explanation
//   const [showFullExplanation, setShowFullExplanation] = useState(false);

//   // Function to toggle the full explanation
//   const toggleExplanation = () => {
//     setShowFullExplanation(!showFullExplanation);
//   };

//   return (
//     <div className="main_blog container">
//       <div className="blogbox">
//         <div className="blogleft">
//           <img src={blogmg} alt="" />
//         </div>
//         <div className="blogright">
//           <div className="blogright-up">
//             <p>
//               This is the brief explanation. It gives a quick overview of the
//               topic.
//             </p>
//           </div>
//           <div className="blogright-down">
//             {showFullExplanation ? (
//               <p>
//                 This is the full explanation. It provides more details and
//                 in-depth information about the topic.
//               </p>
//             ) : (
//               <button onClick={toggleExplanation}>More</button>
//             )}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// import React, { useState } from "react";
// import "./blog.css";
// import blogmg from "./blogimg/upboxbg1.png";

// // Define your blog details with corresponding image paths
// const blogDetails: {
//   [key: string]: { img: string; show: string; detail: string };
// } = {
//   blog1: {
//     img: blogmg,
//     show: "This is a brief overview of Slime.",
//     detail: "Here is the full detailed explanation about Slime.",
//   },
//   blog2: {
//     img: "blogmg",
//     show: "This is a brief overview of Goblin.",
//     detail: "Here is the full detailed explanation about Goblin.",
//   },
//   blog3: {
//     img: "blogmg",
//     show: "This is a brief overview of Elf.",
//     detail: "Here is the full detailed explanation about Elf.",
//   },
// };

// export function Blog() {
//   // State to manage which blog's full explanation is shown
//   const [expandedBlog, setExpandedBlog] = useState<string | null>(null);

//   // Function to toggle the full explanation for a specific blog
//   const toggleExplanation = (blogKey: string) => {
//     setExpandedBlog(expandedBlog === blogKey ? null : blogKey);
//   };

//   return (
//     <div className="main_blog container">
//       {Object.keys(blogDetails).map((key) => (
//         <div className="blogbox" key={key}>
//           <div className="blogleft">
//             <img src={blogDetails[key].img} alt={key} />
//           </div>
//           <div className="blogright">
//             <div className="blogright-up">
//               <p>{blogDetails[key].show}</p>
//             </div>
//             <div className="blogright-down">
//               {expandedBlog === key ? (
//                 <p>{blogDetails[key].detail}</p>
//               ) : (
//                 <button onClick={() => toggleExplanation(key)}>More</button>
//               )}
//             </div>
//           </div>
//         </div>
//       ))}
//     </div>
//   );
// }

import React, { useState } from "react";
import "./blog.css";
import blogmg from "./blogimg/eco.jpg";

// Define your blog details with corresponding image paths
const blogDetails: {
  [key: string]: {
    img: string;
    show: string;
    detail: string;
    header: string;
    video?: string;
  };
} = {
  blog1: {
    img: blogmg,
    show: "Are you looking to get involved in the exciting world of cryptocurrencies?",
    header: "How to participate in the MYG airdrop.",
    detail:
      "Are you looking to get involved in the exciting world of cryptocurrencies? Here’s a fantastic opportunity for you! The MYG token is hosting an airdrop, and you can participate to receive free MYG tokens. Watch the video, join the airdrop, and start earning!",
    video: "https://www.youtube.com/embed/ITdg7gVW8Ak", // Video URL without autoplay
  },
};

export function Blog() {
  const [selectedBlog, setSelectedBlog] = useState<string | null>(null);

  const handleMoreClick = (blogKey: string) => {
    setSelectedBlog(blogKey);
  };

  const handleClose = () => {
    setSelectedBlog(null);
  };

  return (
    <div className="main_blog container">
      <div className="mainblogrow row">
        {Object.keys(blogDetails).map((key) => (
          <div className="blogbox col-12 col-md-12 col-lg-4" key={key}>
            <div className="blogleft">
              <img src={blogDetails[key].img} alt={key} />
            </div>
            <div className="blogright">
              <div className="blogright-up">
                <p>{blogDetails[key].show}</p>
              </div>
              <div className="blogright-down">
                <button
                  onClick={() => handleMoreClick(key)}
                  className="blogright-downbutton"
                >
                  More..
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>

      {selectedBlog && (
        <div className="blogmodal">
          <div className="blogmodal-content">
            <img
              src={blogDetails[selectedBlog].img}
              alt={selectedBlog}
              className="blogmodal-image"
            />
            <div className="blogmodal-detail row">
              <div className="blogdetailbox col-12">
                <h3>{blogDetails[selectedBlog].header}</h3>
                <p>{blogDetails[selectedBlog].detail}</p>
              </div>
            </div>
            {blogDetails[selectedBlog].video && (
              <div className="blogmodal-video">
                <iframe
                  src={`${blogDetails[selectedBlog].video}?autoplay=1`} // Added autoplay
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </div>
            )}
            <div className="blogmodal-buttons">
              <span onClick={handleClose} className="blogcancel">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  fill="currentColor"
                  className="bi bi-x-lg"
                  viewBox="0 0 16 16"
                >
                  <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                </svg>
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
