import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase } from "firebase/database"; // Import the Realtime Database
import { getAuth } from "firebase/auth"; // Import Firebase Authentication

const firebaseConfig = {
  apiKey: "AIzaSyDZsHylM4rA6yAGY7M_8HecDq9yROXvCLw",
  authDomain: "myg-official-account.firebaseapp.com",
  databaseURL: "https://myg-official-account-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "myg-official-account",
  storageBucket: "myg-official-account.appspot.com",
  messagingSenderId: "346789031747",
  appId: "1:346789031747:web:a5bda48fb5f9d5dc7289f6",
  measurementId: "G-WRE76S5ZDV"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp); // Pass the initialized app to getAnalytics

// Initialize Realtime Database
const db = getDatabase(firebaseApp); // Initialize the Realtime Database

// Initialize Firebase Authentication
const auth = getAuth(firebaseApp); // Initialize Firebase Authentication

export { db, auth };
