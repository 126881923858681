import React from "react";
import "./wpaper.css";
import wpaperallo from "./Allocation.png";

export function WPaper() {
  return (
    <div className="maindiv">
      <div className="mainwhitepaper">
        <div className="whitequestions">
          <h3>MYG Token WhitePaper</h3>
          <hr />
          <b style={{ fontSize: "20px" }}> WhitePaper:</b>{" "}
          <a
            href="/MYG whitepaper.pdf"
            download="MYG WhitePaper"
            className="btn btn-primary"
          >
            Download
          </a>
        </div>
        <div className="custom_accordion">
          <div className="accordion accordion-flush" id="accordionFlushExample">
            <div className="accordion-item">
              <h2 className="accordion-header" id="flush-headingOne">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseOne"
                  aria-expanded="false"
                  aria-controls="flush-collapseOne"
                >
                  1 . Introduction
                </button>
              </h2>
              <div
                id="flush-collapseOne"
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingOne"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  <p>
                    {" "}
                    Welcome to the future of decentralized economies, where the{" "}
                    <b>MYG Token</b> powers the core of our unique metaverse.
                    This whitepaper presents our vision, tokenomics, and the
                    ecosystem we are creating for the <b>MYG Token</b>. The
                    metaverse represents a digital frontier where users can
                    create, earn, and govern their experience, with MYG as the
                    underlying currency.{" "}
                  </p>
                </div>
              </div>
            </div>
            {/* 2 */}
            <div className="accordion-item">
              <h2 className="accordion-header" id="flush-headingTwo">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseTwo"
                  aria-expanded="false"
                  aria-controls="flush-collapseTwo"
                >
                  2 . Vision and Mission
                </button>
              </h2>
              <div
                id="flush-collapseTwo"
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingTwo"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  <h4> Vision:</h4>
                  <p>
                    To create a decentralized, user-driven metaverse where users
                    can experience immersive digital worlds, create valuable
                    content, and engage in a thriving economic ecosystem powered
                    by <b> MYG</b>.
                  </p>

                  <h4>Mission: </h4>
                  <p>
                    Our mission is to empower users by offering financial
                    incentives and governance capabilities within the metaverse,
                    building a future where creativity and participation are
                    rewarded and where users truly own their digital assets.{" "}
                  </p>
                </div>
              </div>
            </div>
            {/* 3 */}
            <div className="accordion-item">
              <h2 className="accordion-header" id="flush-headingThree">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseThree"
                  aria-expanded="false"
                  aria-controls="flush-collapseThree"
                >
                  3.Problem Statement
                </button>
              </h2>
              <div
                id="flush-collapseThree"
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingThree"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  <p>
                    As the demand for digital ownership and decentralized
                    virtual experiences grows, traditional gaming and social
                    platforms still operate on centralized models, limiting user
                    ownership, control, and economic opportunities. Users invest
                    time, money, and effort into creating content and engaging
                    with platforms, yet they do not own their in-game assets and
                    cannot meaningfully participate in platform governance.
                  </p>

                  <h5>Some key problems include:</h5>
                  <ul>
                    <li> Lack of true ownership for digital assets. </li>
                    <li>
                      Absence of decentralized governance for digital worlds.
                    </li>
                    <li>Limited economic opportunities for users. </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* 4 */}
            <div className="accordion-item">
              <h2 className="accordion-header" id="flush-headingFour">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseFour"
                  aria-expanded="false"
                  aria-controls="flush-collapseFour"
                >
                  4.MYG Solution
                </button>
              </h2>
              <div
                id="flush-collapseFour"
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingFour"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  <p>
                    <b>MYG token </b> is designed to be the native currency of a
                    decentralized metaverse where users have full ownership over
                    their digital assets, can participate in governance, and
                    earn tokens for engaging in various activities.{" "}
                  </p>

                  <h4>4.1 Use Case Overview</h4>

                  <p>
                    <b>MYG</b> is a multi-functional asset designed to power an
                    entire metaverse ecosystem. Its primary use cases include:
                  </p>
                  <ul>
                    <li>
                      In-Game Currency: MYG is used for buying virtual land,
                      avatars, NFTs, and other in game assets.{" "}
                    </li>
                    <li>
                      {" "}
                      Play-to-Earn: Players earn MYG for participating in games,
                      challenges, and virtual events.
                    </li>
                    <li>
                      Staking and Liquidity: Users can stake MYG tokens to earn
                      rewards or provide liquidity to decentralized exchanges.{" "}
                    </li>
                    <li>
                      Governance: MYG holders can vote on governance proposals
                      to shape the future of the platform.
                    </li>
                  </ul>
                  <br />
                  <hr />
                  <h4>4.2 Metaverse Integration </h4>
                  <p>
                    MYG powers all economic and governance interactions within
                    the metaverse. This allows users to not only experience the
                    digital world but also shape it by owning virtual assets,
                    trading NFTs, and creating unique in-game experiences.{" "}
                  </p>
                </div>
              </div>
            </div>
            {/* 5 */}
            <div className="accordion-item">
              <h2 className="accordion-header" id="flush-headingFive">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseFive"
                  aria-expanded="false"
                  aria-controls="flush-collapseFive"
                >
                  5. MYG Tokenomics
                </button>
              </h2>
              <div
                id="flush-collapseFive"
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingFive"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  <h4>5.1 Total Supply</h4>
                  <ul>
                    <li>
                      {" "}
                      <b>Total Supply</b>: 500,000,000 MYG{" "}
                    </li>
                  </ul>
                  {/* 5.2 */}
                  <br />
                  <hr />
                  <h4>5.2 Token Allocation</h4>
                  <div style={{ textAlign: "center" }}>
                    <img
                      src={wpaperallo}
                      alt="leftcoin"
                      className="wpaperallo"
                    />
                  </div>

                  <ul>
                    <li>
                      <b>Token Sales (22%)</b> – 110,000,000 MYG
                    </li>
                    <ul className="ul_02">
                      <li>
                        For ICO/IDO, private sales, and exchange liquidity.{" "}
                      </li>
                    </ul>
                    <li>
                      <b>Play-to-Earn Rewards (37%)</b> – 185,000,000 MYG{" "}
                    </li>
                    <ul className="ul_02">
                      <li>
                        For rewarding users participating in the metaverse
                        ecosystem.
                      </li>
                    </ul>
                    <li>
                      <b>Staking & Liquidity Rewards (13%)</b> – 65,000,000 MYG{" "}
                    </li>
                    <ul className="ul_02">
                      <li>
                        To incentivize users to stake and provide liquidity.
                      </li>
                    </ul>
                    <li>
                      <b>Team & Advisors (5%)</b> – 25,000,000 MYG{" "}
                    </li>
                    <ul className="ul_02">
                      <li>
                        Allocated to core team members and advisors with vesting
                        schedules.
                      </li>
                    </ul>
                    <li>
                      <b>Community & Marketing (5%)</b> – 25,000,000 MYG{" "}
                    </li>
                    <ul className="ul_02">
                      <li>
                        Dedicated to growing the community and promoting the MYG
                        ecosystem.
                      </li>
                    </ul>
                    <li>
                      <b>Metaverse Development (13%)</b> – 65,000,000 MYG{" "}
                    </li>
                    <ul className="ul_02">
                      <li>
                        Used for future development and expansion of the
                        metaverse.
                      </li>
                    </ul>
                    <li>
                      <b>Ecosystem Development & Reserve Fund (5%)</b> –
                      25,000,000 MYG{" "}
                    </li>
                    <ul className="ul_02">
                      <li>
                        Reserved for unforeseen developments and long-term
                        sustainability.
                      </li>
                    </ul>
                  </ul>
                  {/* 5.3 */}
                  <br />
                  <hr />
                  <h4>5.3 Token Utility</h4>
                  <ul>
                    <li>
                      <b>In-Game Transactions</b>: MYG is used for buying and
                      selling virtual land, items, avatars, and NFTs within the
                      metaverse.{" "}
                    </li>
                    <li>
                      <b>Play-to-Earn Model</b>: Users earn MYG by participating
                      in virtual events, completing challenges, or building
                      content within the ecosystem.{" "}
                    </li>
                    <li>
                      <b>Staking</b>: MYG holders can stake tokens for passive
                      income and participate in governance decisions.{" "}
                    </li>
                    <li>
                      <b>Governance</b>: MYG token holders have voting power to
                      decide on key metaverse developments, feature launches,
                      and token economic policies.
                    </li>
                  </ul>
                  {/* 5.4 */}
                  <br />
                  <hr />
                  <h4>5.4 Play-to-Earn Model</h4>
                  <h5>Players and content creators can earn MYG tokens by:</h5>
                  <ul>
                    <li>Completing in-game quests and challenges. </li>
                    <li>
                      Creating content such as buildings, avatars, or items for
                      other users.{" "}
                    </li>
                    <li>Participating in virtual events or tournaments</li>
                  </ul>
                  {/* 5.5 */}
                  <br />
                  <hr />
                  <h4>5.5 Staking & Liquidity </h4>
                  <p>
                    Staking and liquidity rewards ensure that MYG holders have
                    an opportunity to earn passive rewards by contributing to
                    the stability and security of the ecosystem. MYG tokens can
                    be staked to earn rewards or used to provide liquidity in
                    decentralized exchanges like Uniswap.
                  </p>
                  {/* 5.6 */}
                  <br />
                  <hr />
                  <h4>5.6 Governance & DAO</h4>
                  <p>
                    The future of the metaverse will be shaped by the community.
                    Token holders will be able to vote on proposals related to
                    the development and governance of the virtual world,
                    including decisions about partnerships, features, and
                    economic changes. A decentralized autonomous organization
                    (DAO) will be set up to facilitate this process.
                  </p>
                  {/* 5.7 */}
                  <br />
                  <hr />
                  <h4>5.7 Burning Mechanism</h4>
                  <p>
                    The burning mechanism is a crucial aspect of MYG tokenomics,
                    designed to create scarcity, boost token value, and
                    incentivize long-term holdings. Here's how the burning
                    mechanism works:
                  </p>
                  <ol>
                    <li>
                      In-Game Spending Burns: When users buy assets like virtual
                      land and avatars or upgrade in-game items, (5%) of the
                      transaction will be burned.
                    </li>
                    <li>
                      Periodic Burns: Regular burns will occur quarterly based
                      on the total tokens spent in the ecosystem. These burns
                      will be transparent and verifiable by the community.
                    </li>
                    <li>
                      Special Event Burns: Limited-time events, tournaments, or
                      large-scale partnerships will trigger additional burns,
                      with some rewards removed from the circulating supply.
                    </li>
                  </ol>
                  <p>
                    These mechanisms help reduce the overall supply of MYG over
                    time, making the token scarcer and increasing its value in
                    the long run.
                  </p>
                  {/* 5.8 */}
                  <br />
                  <hr />
                  <h4>5.8 Vesting Schedule</h4>
                  <p>
                    To ensure a stable and sustainable ecosystem, a vesting
                    schedule has been implemented for the allocation of MYG
                    tokens to the team, advisors, and investors. The vesting
                    schedule is as follows:
                  </p>

                  <table>
                    <tr>
                      <td>Category</td>
                      <td>Lock-up Period </td>
                      <td>Vesting Period</td>
                      <td>Release Frequency</td>
                    </tr>
                    <tr>
                      <td>Team</td>
                      <td>6 Months </td>
                      <td>24 Months</td>
                      <td>Monthly</td>
                    </tr>
                    <tr>
                      <td>Advisors</td>
                      <td>3 Months </td>
                      <td>12 Months</td>
                      <td>Quarterly</td>
                    </tr>
                    <tr>
                      <td>Public Sale Investors</td>
                      <td>0 Months </td>
                      <td>4 Months</td>
                      <td>Monthly</td>
                    </tr>
                  </table>
                  {/* 5.9 */}
                  <br />
                  <hr />
                  <h4>5.9 Vesting Schedule Explanation</h4>
                  <ul>
                    <li>
                      <b>Team</b>: Tokens allocated to the team will have a{" "}
                      <b>6-month lock-up period</b> to prevent immediate
                      selling, followed by a <b>24-month vesting period</b> with
                      monthly releases. This structure ensures that the team is
                      incentivized to contribute to the long-term success of the
                      project.
                    </li>
                    <li>
                      <b>Advisors</b>: Advisors will have a{" "}
                      <b>3-month lock-up period</b> followed by a{" "}
                      <b>12-month vesting period</b> with quarterly releases.
                      This allows for gradual rewards as they provide ongoing
                      support and guidance.
                    </li>

                    <li>
                      <b>Public Sale Investors</b>: Public sale participants
                      will receive their tokens gradually over a 4-month vesting
                      period, with no initial lock-up. Tokens will be
                      distributed monthly, allowing for steady engagement and
                      trading within the ecosystem.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* 6 */}
            <div className="accordion-item">
              <h2 className="accordion-header" id="flush-heading6">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapse6"
                  aria-expanded="false"
                  aria-controls="flush-collapse6"
                >
                  6.Roadmap
                </button>
              </h2>
              <div
                id="flush-collapse6"
                className="accordion-collapse collapse"
                aria-labelledby="flush-heading6"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  <table>
                    <tr>
                      <td>Phase </td>
                      <td>Timeframe</td>
                      <td>Key Activities</td>
                    </tr>
                    <tr>
                      <td>Phase 1</td>
                      <td>Q4 2024</td>
                      <td className="roadleft">
                        <ul>
                          <li>
                            First Game Release: Officially launch the first
                            play-to-earn game for earning MYG tokens, Bitcoin,
                            and Ethereum.
                          </li>
                          <li>
                            Token Sale: Launch MYG token via an ICO/IDO and
                            begin liquidity provisioning on DEXs.
                          </li>
                          <li>
                            Platform Development: Ensure scalability and support
                            for multitoken rewards.
                          </li>
                          <li>
                            Community Engagement: Drive growth through campaigns
                            and AMAs focused on the gaming experience.
                          </li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td>Phase 2</td>
                      <td>Q2 2025 </td>
                      <td className="roadleft">
                        <ul>
                          <li>
                            Second Game Release: Launch the second play-to-earn
                            game with enhanced earning opportunities.
                          </li>
                          <li>
                            NFT Marketplace Launch: Introduce a marketplace for
                            trading game assets and NFTs.
                          </li>
                          <li>
                            -Strategic Partnerships: Collaborate with gaming
                            influencers and crypto communities to broaden
                            audience reach.
                          </li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td>Phase 3</td>
                      <td>Q3 2025</td>
                      <td className="roadleft">
                        <ul>
                          <li>
                            Third Game Release: Launch the third game with
                            cross-game integration of MYG tokens and in-game
                            assets.
                          </li>
                          <li>
                            Player Incentives: Organize tournaments and events
                            rewarding players with MYG, Bitcoin, and Ethereum.
                          </li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td>Phase 4 </td>
                      <td>Q4 2025</td>
                      <td className="roadleft">
                        <ul>
                          <li>
                            Fourth Game Release: Launch the fourth game,
                            continuing the multi-token reward system.
                          </li>
                          <li>
                            Ecosystem Expansion: Enhance user experience and
                            prepare for adoption.
                          </li>
                          <li>
                            -Token Listing Preparations: Finalize preparation
                            for MYG token listing on major exchanges (e.g.,
                            Binance, OKX).
                          </li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td>Phase 5</td>
                      <td>Q5 2025</td>
                      <td className="roadleft">
                        <ul>
                          <li>
                            Token Listing: Officially list MYG on major
                            exchanges for global accessibility and liquidity.
                          </li>
                          <li>
                            Metaverse Integration: Initiate Metaverse
                            integration, allowing ownership and trading of
                            virtual assets.
                          </li>
                          <li>
                            Long-Term Development: Announce plans for expanding
                            the Metaverse, introducing DAO for community
                            governance,
                            <br /> and ongoing rewards in MYG, Bitcoin, and
                            Ethereum
                          </li>
                        </ul>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            {/* 7 */}
            <div className="accordion-item">
              <h2 className="accordion-header" id="flush-heading7">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapse7"
                  aria-expanded="false"
                  aria-controls="flush-collapse7"
                >
                  7.Team
                </button>
              </h2>
              <div
                id="flush-collapse7"
                className="accordion-collapse collapse"
                aria-labelledby="flush-heading7"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  <p>
                    We are a dedicated team of developers, blockchain experts,
                    and innovators with extensive experience in building
                    decentralized applications, gaming ecosystems, and virtual
                    worlds. For now, the MYG team has chosen to remain anonymous
                    to focus on delivering the best product without external
                    distractions.
                  </p>
                  <p>
                    Our commitment is to the success of the MYG ecosystem and
                    its community. Despite the team's anonymity, we are
                    transparent about the project’s development and security. To
                    further assure the community, we have implemented the
                    following measures:
                  </p>
                  <ol>
                    <li>
                      <b>Third-Party Smart Contract Audits</b> : We have
                      partnered with reputable blockchain security firms to
                      audit our smart contracts, ensuring they are secure and
                      free from vulnerabilities.
                    </li>
                    <li>
                      <b>Regular Development Updates</b> : We will provide
                      consistent updates on progress, milestones, and community
                      engagement to maintain transparency and accountability.
                    </li>
                    <li>
                      <b> Multi-Sig Wallets</b> : The project’s funds are
                      secured through multi-signature wallets, ensuring that no
                      single individual has unilateral control over the
                      project’s finances. This structure provides additional
                      security and trust.
                    </li>
                    <li>
                      <b>Decentralized Governance (DAO)</b> : As the project
                      progresses, we will gradually hand over decision-making
                      power to the community via a Decentralized Autonomous
                      Organization (DAO). This ensures that the MYG ecosystem is
                      driven by its users, not just the core team.
                    </li>
                  </ol>
                  <p>
                    We believe in the power of decentralized communities, and
                    our focus is on creating an open and transparent ecosystem
                    where the technology and vision speak for themselves.
                  </p>
                </div>
              </div>
            </div>
            {/* 8 */}
            <div className="accordion-item">
              <h2 className="accordion-header" id="flush-heading8">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapse8"
                  aria-expanded="false"
                  aria-controls="flush-collapse8"
                >
                  8. Partnerships
                </button>
              </h2>
              <div
                id="flush-collapse8"
                className="accordion-collapse collapse"
                aria-labelledby="flush-heading8"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  <h4>8.1 Unity</h4>
                  <p>
                    Unity is one of the most popular real-time 3D development
                    platforms, widely used for creating immersive gaming
                    experiences. By partnering with Unity, MYG leverages its
                    cutting-edge engine to develop a high-quality, interactive,
                    and visually engaging metaverse environment. This
                    collaboration enables the seamless integration of MYG into
                    games and interactive experiences, ensuring compatibility
                    with a range of devices and providing an immersive
                    experience for users.
                  </p>
                  <br />
                  <hr />
                  <h4>8.2 Polygon (formerly Matic)</h4>
                  <p>
                    Polygon is a leading Ethereum Layer-2 scaling solution that
                    addresses issues of scalability, transaction speed, and gas
                    fees. Our partnership with Polygon ensures that the MYG
                    ecosystem benefits from fast and cost-effective
                    transactions, making participation in the metaverse smooth
                    and accessible. Polygon's advanced scaling solutions allow
                    MYG to provide an efficient infrastructure for decentralized
                    applications (dApps) and in-game economies, empowering users
                    to interact with the MYG token and NFTs without high
                    transaction costs.
                  </p>
                  <br />
                  <hr />
                  <h4>8.3 OpenSea</h4>
                  <p>
                    OpenSea is the largest decentralized marketplace for NFTs,
                    where users can create, buy, sell, and trade digital assets.
                    Through our partnership with OpenSea, MYG integrates
                    seamlessly with the NFT marketplace, allowing users to
                    easily trade their in-game assets, virtual real estate, and
                    unique collectibles. This collaboration opens a global
                    market for MYG's digital assets, giving creators and players
                    the opportunity to monetize their in-game achievements and
                    assets within the broader NFT ecosystem.
                  </p>
                  <br />
                  <hr />
                  <h4>8.4 UniSwap</h4>
                  <p>
                    Uniswap is the largest decentralized exchange (DEX) on
                    Ethereum, enabling trustless token swaps and liquidity
                    provision. Our partnership with Uniswap ensures that MYG
                    token holders can easily trade MYG with other ERC-20 tokens
                    in a decentralized and secure manner. Through Uniswap’s
                    liquidity pools, users can also provide liquidity and earn
                    rewards in the form of MYG tokens, further enhancing the
                    token's utility and accessibility within the DeFi space.
                    This integration facilitates liquidity for the MYG token,
                    supporting healthy trading activity and market stability.
                  </p>
                </div>
              </div>
            </div>
            {/* 9 */}
            <div className="accordion-item">
              <h2 className="accordion-header" id="flush-heading9">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapse9"
                  aria-expanded="false"
                  aria-controls="flush-collapse9"
                >
                  9. Security & Audits
                </button>
              </h2>
              <div
                id="flush-collapse9"
                className="accordion-collapse collapse"
                aria-labelledby="flush-heading9"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  <p>
                    Security is paramount for the success of MYG. We are
                    committed to:
                  </p>
                  <ul>
                    <li>
                      <b>Smart Contract Audits</b> : Our smart contracts will be
                      audited by leading blockchain security firms to ensure the
                      safety of user funds and assets.
                    </li>
                    <br />
                    <li>
                      <b>Bug Bounty Program</b> : A bounty program will be
                      launched to incentivize developers to identify and report
                      vulnerabilities.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* 10 */}
            <div className="accordion-item">
              <h2 className="accordion-header" id="flush-heading10">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapse10"
                  aria-expanded="false"
                  aria-controls="flush-collapse10"
                >
                  10. Conclusion
                </button>
              </h2>
              <div
                id="flush-collapse10"
                className="accordion-collapse collapse"
                aria-labelledby="flush-heading10"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  <p>
                    The MYG token is not just a digital currency, but the key to
                    unlocking a decentralized, usergoverned metaverse. With
                    innovative play-to-earn mechanisms, governance models, and
                    NFT integration, the MYG ecosystem is designed to empower
                    users, developers, and creators. We invite you to join us on
                    this journey to shape the future of the digital world.
                  </p>
                </div>
              </div>
            </div>
            {/* 11 */}
            <div className="accordion-item">
              <h2 className="accordion-header" id="flush-heading11">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapse11"
                  aria-expanded="false"
                  aria-controls="flush-collapse11"
                >
                  11. Disclaimer
                </button>
              </h2>
              <div
                id="flush-collapse11"
                className="accordion-collapse collapse"
                aria-labelledby="flush-heading11"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  <p>
                    This whitepaper is for informational purposes only and does
                    not constitute an offer to sell or a solicitation of an
                    offer to buy MYG tokens. Participation in token sales
                    involves significant risk and is only suitable for
                    individuals or entities who fully understand and are willing
                    to assume the risks involved.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
