// import React, { useState } from "react";
// import "./eco.css";
// import blogmg from "./ecoimg/upboxbg1.png";

// // Define your blog details with corresponding image paths
// const blogDetails: {
//   [key: string]: { img: string; show: string; link: string; header: string };
// } = {
//   blog1: {
//     img: blogmg,
//     show: "This is a brief overview of Slime.",
//     header: "blah",
//     link:"https://t.me/Bitbrawlofficial_bot",
//   },
//   blog2: {
//     img: blogmg,
//     show: "This is a brief overview of Goblin.",
//     header: "blah",
//     link:"https://t.me/Bitbrawlofficial_bot",
//   },
// };

// export function Eco() {
//   const [selectedBlog, setSelectedBlog] = useState<string | null>(null);

//   const handleMoreClick = (blogKey: string) => {
//     setSelectedBlog(blogKey);
//   };

//   const handleClose = () => {
//     setSelectedBlog(null);
//   };

//   return (
//     <div className="main_eco container">
//       <div className="mainecorow row">
//         {Object.keys(blogDetails).map((key) => (
//           <div className="ecobox col-12 col-md-12 col-lg-4" key={key}>
//             <div className="blogleft">
//               <img src={blogDetails[key].img} alt={key} />
//             </div>
//             <div className="ecoright">
//               <div className="ecoright-up">
//                 <p>{blogDetails[key].show}</p>
//               </div>
//               <div className="ecoright-down">
//                 <button
//                   onClick={() => handleMoreClick(key)}
//                   className="ecoright-downbutton"
//                 >
//                   Click here
//                 </button>
//               </div>
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// }

import React from "react";
import "./eco.css";
import blogmg from "./ecoimg/eco.jpg";

// Define your blog details with corresponding image paths and links
const blogDetails: {
  [key: string]: { img: string; show: string; link: string; header: string };
} = {
  blog1: {
    img: blogmg,
    show: "Get Rewarded with MYG Tokens: Join Our Telegram Airdrop Now!",
    header: "Join Airdrop",
    link: "https://t.me/Bitbrawlofficial_bot",
  },
};

export function Eco() {
  return (
    <div className="main_eco container">
      <div className="mainecorow row">
        {Object.keys(blogDetails).map((key) => (
          <div className="ecobox col-12 col-md-12 col-lg-12" key={key}>
            <div className="blogleft">
              <img src={blogDetails[key].img} alt={key} />
            </div>
            <div className="ecoright">
              <div className="ecoright-up">
                <p>{blogDetails[key].show}</p>
              </div>
              <div className="ecoright-down">
                <a
                  href={blogDetails[key].link}
                  className="ecoright-downbutton"
                  target="_blank" // This will open the link in a new tab
                  rel="noopener noreferrer" // This is a security measure when using target="_blank"
                >
                  Click here to Join
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
